import React, { useEffect, useState } from "react";
import { Headline } from "design-system";
import * as Sentry from "@sentry/react";

import {
  DeprecatedInvoiceSkeleton,
  DeprecatedEmbeddableDashboardInvoice,
} from "components/deprecated/Invoice";

import { useRecentInvoices } from "./useRecentInvoices";
import { InvoiceList } from "./InvoicesList";
import { EmptyState } from "components/EmptyState";
import { EmbeddableDashboardContext } from "embeddable-dashboards/lib/embeddableDashboardContext";

const Invoices: React.FC = () => {
  const { statuses, showChargesWithZeroUsage } =
    EmbeddableDashboardContext.useContainer();
  const [activeInvoiceId, setActiveInvoiceId] = useState<string>();
  const invoicesReq = useRecentInvoices({
    variables: {
      limit: 100,
      statuses,
    },
    onCompleted(data) {
      if (data.Customer[0].recentInvoices.invoices.length > 0) {
        setActiveInvoiceId(data.Customer[0].recentInvoices.invoices[0].id);
      }
    },
  });

  useEffect(() => {
    if (invoicesReq.state !== "success") {
      return;
    }

    Sentry.setUser({
      id: invoicesReq.customer.id,
    });

    setTimeout(() => {
      const spinner = document.getElementById("spinner");
      if (spinner) {
        spinner.style.display = "none";
      }
    }, 10);
  }, [invoicesReq]);

  if (invoicesReq.state === "not found") {
    return <EmptyState mainText="Invoice not found" icon="file05" />;
  }

  if (invoicesReq.state === "error") {
    return (
      <EmptyState mainText="We were unable to load invoices." icon="fileX02" />
    );
  }

  const invoice =
    invoicesReq.state === "success"
      ? invoicesReq.invoices.find((i) => i.id === activeInvoiceId)
      : undefined;
  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="flex h-full w-full grow flex-row overflow-hidden">
        <div className="h-full w-1/3 max-w-lg shrink-0 grow-0 overflow-auto border-r border-r-deprecated-gray-light pr-12">
          <Headline level={5} className="my-12 text-sm font-medium leading-1">
            Invoice History
          </Headline>
          <InvoiceList
            loading={invoicesReq.state === "loading"}
            activeInvoiceId={activeInvoiceId}
            setActiveInvoiceId={setActiveInvoiceId}
            invoices={
              invoicesReq.state === "success" ? invoicesReq.invoices : []
            }
          />
        </div>
        <div className="flex h-full shrink grow flex-col overflow-auto">
          {invoicesReq.state === "loading" ||
          (!activeInvoiceId && invoicesReq.invoices.length) ? (
            <DeprecatedInvoiceSkeleton />
          ) : !activeInvoiceId ? (
            <EmptyState
              icon="receipt"
              mainText="No invoices found for this customer"
              supportingText=""
            />
          ) : invoice ? (
            <DeprecatedEmbeddableDashboardInvoice
              invoice={invoice}
              customer={invoicesReq.customer}
              showChargesWithZeroUsage={showChargesWithZeroUsage}
            />
          ) : (
            <EmptyState
              icon="receipt"
              mainText="No invoices found for this customer"
              supportingText=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoices;
