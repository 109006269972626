import { dayjs } from "lib/dayjs";

import * as Sentry from "@sentry/react";
import React, { useState, useEffect, useRef, useMemo } from "react";

import classnames from "classnames";
import styles from "./index.module.css";
import {
  DeprecatedGraph,
  TimeSeries,
  TimeSeriesDatum,
} from "components/deprecated/Graph";
import {
  Select,
  Subtitle,
  Headline,
  Toggle,
  graphLineColors,
} from "design-system";
import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";

import { useRetrieveCustomerUsageQuery } from "./queries.graphql";
import { addDays, differenceInDays } from "date-fns";
import { EmptyState } from "components/EmptyState";
import { EmbeddableDashboardContext } from "embeddable-dashboards/lib/embeddableDashboardContext";

type GroupedMetric = {
  name: string;
  group_key: string | null;
  data: TimeSeries[];
  hasData: boolean;
};

function hashString(str: string) {
  let hash = 0;
  let i = str.length;
  while (i) {
    hash += str.charCodeAt(--i);
  }
  return hash;
}

const defaultColors = [...graphLineColors];

// Returns a consistent color given an ID
function idToColorIndex(id: string) {
  const hash = hashString(id);
  return Math.abs(hash) % defaultColors.length;
}

function populateEmptyData(startDay: Date, endDay: Date): TimeSeriesDatum[] {
  const dayCount = differenceInDays(endDay, startDay);
  return Array(dayCount)
    .fill(0)
    .map((_, idx) => ({
      date: addDays(startDay, idx),
      value: 0,
    }));
}

function getColors(colorCount: number, randomSeed: string): string[] {
  const colors = [];

  // keep some consistency in the colors an ensure that colors are not repeated (unless more than 10 colors are needed)
  let index = idToColorIndex(randomSeed);
  for (let i = 0; i < colorCount; i++) {
    colors.push(defaultColors[index]);
    index++;
    if (index >= defaultColors.length) {
      index = 0;
    }
  }
  return colors;
}

const CustomerUsageDashboard: React.FC = () => {
  const { getGroupValueDisplayName } =
    EmbeddableDashboardContext.useContainer();

  const [dashboardsContainerHeight, setDashboardsContainerHeight] = useState(0);
  const timeWindowRef = useRef<HTMLDivElement>(null);

  function handleResize() {
    const { clientHeight: timeWindowHeight } = timeWindowRef?.current || {
      clientHeight: null,
    };

    if (timeWindowHeight !== null) {
      setDashboardsContainerHeight(window.innerHeight - timeWindowHeight);
    }
  }

  useEffect(() => {
    if (window.location.search.includes("colorOverrides=")) {
      const urlParams = new URLSearchParams(window.location.search);
      try {
        const colorOverrides = JSON.parse(
          urlParams.get("colorOverrides") || "[]",
        ) as Array<{ name: string; value: string }>;

        colorOverrides
          .filter(({ name }) => name.startsWith("usageLine"))
          .forEach(({ name, value }) => {
            const index = parseInt(name.replace("usageLine", ""), 10);
            if (index >= 0 && index < defaultColors.length) {
              defaultColors[index] = value;
            }
          });
      } catch {
        // do nothing
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [showZeroUsageMetrics, setShowZeroUsageMetrics] = useState(false);
  const [selectedUsageRange, setSelecteUsageRange] = useState(30);
  const endDayjs = dayjs.utc().startOf("day").add(1, "day");
  const startDayjs = endDayjs.subtract(selectedUsageRange, "day");

  const { data, refetch, loading } = useRetrieveCustomerUsageQuery({
    variables: {
      start_date: startDayjs.toISOString() ?? "",
      end_date: endDayjs.toISOString() ?? "",
    },
  });

  const customer = data?.Customer[0];

  useEffect(() => {
    if (loading) {
      return;
    }

    Sentry.setUser({
      id: customer?.id ?? "unknown",
    });

    setTimeout(() => {
      handleResize();
    }, 10);
  }, [loading, customer]);

  // Refetch usage every 60 seconds. This is so changes show up in
  // pseudo-realtime, enabling (at the very least) better demos.
  useEffect(() => {
    const interval = setInterval(() => {
      void refetch();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // Because we load the usage and metrics as part of one query, as the user changes the start/end
  // date, we "refetch" all the metrics. This causes the UI to revert back into the skeleton loading
  // state. In order to make this less disruptive for the user, we store a "high water mark" of the
  // number of metrics we're displaying, so we can show the correct number of skeleton graphs instead
  // of just reverting back to 1 as you change the date range
  const [numberOfMetrics, setNumberOfMetrics] = useState(0);
  useEffect(() => {
    setNumberOfMetrics(
      Math.max(numberOfMetrics, customer?.current_usage?.length ?? 3),
    );
  }, [customer]);

  const groupedMetrics: GroupedMetric[] = useMemo(() => {
    if (customer?.current_usage) {
      const metrics = [...customer.current_usage].sort((a, b) =>
        (a.billable_metric.name || "").localeCompare(
          b.billable_metric.name || "",
        ),
      );

      // find out how many group values there are, we will create empty data for each
      // then will loop through the usage data and populate the data for each group
      return metrics.map((metric) => {
        const groupValues = new Set(
          metric.usage.flatMap((u) => u.groups ?? []).map((g) => g.group_value),
        );

        // if no groups, then set the default group to the metric name
        if (groupValues.size === 0) {
          groupValues.add(metric.billable_metric.name);
        }

        const colors = getColors(groupValues.size, metric.billable_metric.id);

        const metricByGroupMap = new Map<string, TimeSeries>();
        Array.from(groupValues).forEach((groupValue, idx) => {
          metricByGroupMap.set(groupValue, {
            color: colors[idx],
            name:
              getGroupValueDisplayName(metric.group_key ?? "", groupValue) ??
              groupValue,
            data: populateEmptyData(startDayjs.toDate(), endDayjs.toDate()),
          });
        });

        // now populate the data values for each group
        metric.usage.forEach((usage) => {
          if (usage.groups.length > 0) {
            usage.groups.forEach((group) => {
              const groupValue = metricByGroupMap.get(group.group_value);
              if (groupValue?.data) {
                const index = groupValue.data.findIndex((d) =>
                  dayjs.utc(usage.start).isSame(d.date, "day"),
                );
                if (index !== -1) {
                  groupValue.data[index].value = Number(
                    group.metric_value ?? 0,
                  );
                }
              }
            });
          } else {
            // if no groups, then set the default group to the metric name
            const val = metricByGroupMap.get(metric.billable_metric.name);
            if (val?.data) {
              const index = val.data.findIndex((d) =>
                dayjs.utc(usage.start).isSame(d.date, "day"),
              );
              if (index !== -1) {
                val.data[index].value = Number(usage.metric_value ?? 0);
              }
            }
          }
        });

        const data = Array.from(metricByGroupMap.values());
        const hasData = data.some((d) => d.data.some((d) => d.value > 0));
        return {
          group_key: metric.group_key,
          name: metric.billable_metric.name,
          data,
          hasData,
        };
      });
    }

    return [];
  }, [customer, selectedUsageRange]);

  const hasMetricsWithoutUsage = useMemo(() => {
    return groupedMetrics.some((m) => !m.hasData);
  }, [groupedMetrics]);
  return (
    <div className="flex flex-col">
      <div ref={timeWindowRef}>
        <div
          className={classnames([
            "flex",
            "py-8",
            "justify-between",
            "items-end",
            "border-b-deprecated-gray-100",
            "border-b-[1px]",
            "font-medium",
            "flex-shrink-0",
          ])}
        >
          <Headline level={5}>Your recent usage</Headline>
          <div className="flex flex-row items-center gap-8">
            {hasMetricsWithoutUsage && (
              <Toggle
                checked={showZeroUsageMetrics}
                label="Show zero usage metrics"
                onChange={setShowZeroUsageMetrics}
                className={styles.toggleLabel}
              />
            )}
            <div className="w-[124px]">
              <Select
                options={[30, 60, 90].map((d) => {
                  return { value: d.toString(), label: `Last ${d} days` };
                })}
                value={selectedUsageRange.toString()}
                placeholder="30"
                onChange={(v) => setSelecteUsageRange(Number(v))}
                className={styles.dropdownLabel}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-wrap content-start overflow-auto"
        style={{ height: dashboardsContainerHeight + "px" }}
      >
        {loading ? (
          [...Array(4)].map((_, i) => (
            <div
              key={i}
              className={classnames([
                "bg-background w-[50%] border-b border-l border-deprecated-gray-100 p-12",
                styles.shadow,
              ])}
            >
              <DeprecatedTextSkeleton />
              <div className="h-[240px]">
                <DeprecatedGraph key={i} loading />
              </div>
            </div>
          ))
        ) : groupedMetrics.length === 0 ? (
          <div className="flex h-full w-full">
            <EmptyState
              icon="receipt"
              mainText="No usage found for this customer"
              supportingText=""
            />
          </div>
        ) : (
          groupedMetrics
            .filter((m) => m.hasData || showZeroUsageMetrics)
            .map((metric, idx) => (
              <div
                key={metric.name || ""}
                className={classnames(
                  "bg-background h-fit border-b border-r border-deprecated-gray-100 p-12",
                  groupedMetrics.length <= 2
                    ? "w-full border-l"
                    : `w-[50%] ${
                        idx % 2 === 0 || idx === groupedMetrics.length - 1
                          ? "border-l"
                          : ""
                      }`,
                )}
              >
                <Subtitle>
                  {metric.name}
                  {/* TODO(ekaragiannis) - fix this !!metric.group_key && ` - Group by: ${metric.group_key}` */}
                </Subtitle>
                <div className="h-[240px]">
                  <DeprecatedGraph
                    showLegend="below"
                    lines={metric.data}
                    isUTC={true}
                  />
                </div>
              </div>
            ))
        )}
      </div>
    </div>
  );
};

export default CustomerUsageDashboard;
