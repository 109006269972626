import React from "react";
import { DeprecatedTable } from "components/deprecated/Table";
import Decimal from "decimal.js";
import classnames from "classnames";
import styles from "./index.module.less";
import { RoundedCurrency } from "app/lib/credits";
import { renderDate } from "lib/time";
import { CreditType } from "app/types/credit-types";
import {
  CommitLedger,
  CommitLedgerEntry,
  Ledger,
  LedgerEntry,
} from "../../types";
import { Badge, Caption, Sidenote } from "design-system";
import classNames from "classnames";

type CreditLedgerDateProps = {
  ledgerEntry: LedgerEntry;
};
const CreditLedgerDate: React.FC<CreditLedgerDateProps> = ({ ledgerEntry }) => {
  const date = renderDate(
    new Date(
      !ledgerEntry.grant.voidedAt
        ? ledgerEntry.effectiveAt
        : ledgerEntry.grant.voidedAt,
    ),
    {
      isUtc: true,
      excludeUtcLabel: true,
    },
  );

  const badgeDateInfo = (caption: string) => {
    return (
      <div className={classNames("flex items-center", styles.ledgerBadge)}>
        <Caption level={1} className={styles.ledgerBadgeCaption}>
          {caption}
        </Caption>
        &nbsp;
        <Sidenote className={styles.ledgerBadgeDate}>({date})</Sidenote>
      </div>
    );
  };

  switch (ledgerEntry.__typename) {
    case "MRI_CreditExpirationCreditLedgerEntry":
      return (
        <Badge theme="error" type="light" className={styles.expiryBadge}>
          {badgeDateInfo("Expired")}
        </Badge>
      );
    case "MRI_PendingChargeCreditLedgerEntry":
      return (
        <Badge theme="warning" type="light" className={styles.pendingBadge}>
          {badgeDateInfo("Pending")}
        </Badge>
      );
    case "MRI_PendingCreditExpirationCreditLedgerEntry":
      return (
        <Badge theme="error" type="light" className={styles.expiryBadge}>
          {badgeDateInfo("Will expire")}
        </Badge>
      );
    case "MRI_CreditGrantCreditLedgerEntry":
      if (ledgerEntry.grant.voidedAt) {
        return (
          <Badge theme="warning" type="light" className={styles.voidedBadge}>
            {badgeDateInfo("Voided")}
          </Badge>
        );
      }
      if (ledgerEntry.effectiveAt > new Date()) {
        return (
          <Badge theme="warning" type="light" className={styles.pendingBadge}>
            {badgeDateInfo("Pending")}
          </Badge>
        );
      }
      return <>{date}</>;
    case "MRI_CreditDeductionCreditLedgerEntry":
    default:
      if (ledgerEntry.effectiveAt > new Date()) {
        return (
          <Badge theme="warning" type="light" className={styles.pendingBadge}>
            {badgeDateInfo("Pending")}
          </Badge>
        );
      }
      return <>{date}</>;
  }
};

// Using <RoundedCurrency />, but formatting and styling in the accounting way
const AccountingFormattedCurrency: React.FC<{
  amount: Decimal;
  creditType: CreditType;
  voided: boolean;
}> = ({ amount, creditType, voided }) => {
  return (
    <span
      className={classnames({
        [styles.positive]: amount.greaterThan(0),
        [styles.negative]: amount.lessThan(0),
        [styles.voided]: voided,
      })}
    >
      <RoundedCurrency amount={amount} creditType={creditType} />
    </span>
  );
};

type CreditOrCommitLedgerProps = {
  ledger: Ledger | CommitLedger;
  noBottomBorder?: boolean;
};
const CreditOrCommitLedger: React.FC<CreditOrCommitLedgerProps> = ({
  ledger,
  noBottomBorder,
}) => {
  return (
    <DeprecatedTable
      loading={false}
      maxPageSize={10}
      data={ledger.ledgerEntries}
      noBottomBorder={noBottomBorder}
      columns={[
        {
          header: "Amount",
          id: "amount",
          render: (entry: LedgerEntry | CommitLedgerEntry) => {
            const amount = new Decimal(entry.amount);
            const voided = "grant" in entry ? !!entry.grant.voidedAt : false;
            return (
              <AccountingFormattedCurrency
                amount={amount}
                creditType={ledger.creditType}
                voided={voided}
              />
            );
          },
          align: "left" as const,
        },
        {
          header: "Balance",
          id: "balance",
          render: (entry: LedgerEntry | CommitLedgerEntry) =>
            "runningBalance" in entry ? (
              <RoundedCurrency
                amount={new Decimal(entry.runningBalance)}
                creditType={ledger.creditType}
              />
            ) : (
              <p>todo for commits</p>
            ),
          align: "left" as const,
        },
        {
          header: "Date",
          id: "date",
          render: (entry: LedgerEntry | CommitLedgerEntry) =>
            "grant" in entry ? (
              <CreditLedgerDate ledgerEntry={entry} />
            ) : (
              <p>todo for commits</p>
            ),
          align: "right" as "right",
        },
      ]}
    />
  );
};

export default CreditOrCommitLedger;
